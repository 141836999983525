<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    class="mr-2"
    variant="light"
    type="button"
    v-on="$listeners"
  >
    <font-awesome-icon
      prefix="far"
      :icon="['far', 'times-circle']"
      class="mr-50"
    />
    Fechar
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ButtonClose',
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
}
</script>

<style>

</style>

const map = [
  {
    path: '/maps',
    name: 'maps.list',
    component: () => import('@/modules/map/views/MapList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/create',
    name: 'maps.create',
    component: () => import('@/modules/map/views/MapForm.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/show/:uuid',
    name: 'maps.edit',
    component: () => import('@/modules/map/views/MapForm.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/:uuid/period',
    name: 'maps-period.edit',
    component: () => import('@/modules/timemap/component/TimeMapPeriod.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/:uuid/resources',
    name: 'maps-resources.edit',
    component: () => import('@/modules/timemap/component/TimeMapPeriodResource.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default map
